

















































































// Core
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { userNamespace } from '@store/user'
import './about.scss'
import { RequestParams } from '@store/interfaces'
import { UsersActions } from '@/store/users/types'
import { LegalPerson, UsersState } from '@/store/users/interfaces'
import { Project } from '@/store/projects/interfaces'
import { parse, format } from 'date-fns'

// Modules
const NSUsers = namespace('usersModule')

@Component({
  components: {
    'v-natural-persons': () => import('./components/NaturalPersons.vue'),
    'v-legal-persons': () => import('./components/LegalPersons.vue'),
    'v-dialog-project-info': () => import('@/components/dialogs/DialogProjectInfo.vue'),
    'v-card': () => import('./components/Card.vue'),
  },
})
export default class About extends Vue {
  @NSUsers.State((state: UsersState) => state.legals)
  private legalPersons!: LegalPerson[]

  @NSUsers.Action(UsersActions.A_FETCH_BY_ROLES_LEGAL_PERSONS)
  private fetchLegalPersons!: (params?: RequestParams) => Promise<LegalPerson[]>

  @userNamespace.Getter('project')
  private project!: Project

  private activeName: string = 'individuals'
  private visibleDialogFormAbout: boolean = false

  private dialogLegalPerson = { name: '' }

  created() {
    this.fetchLegalPersons({ projectId: this.project.id })
  }

  private formatDate(date: string): string {
    try {
      if (date) {
        const dateFns = parse(date, 'yyyy-MM-dd', new Date())

        return format(dateFns, 'dd.MM.yyyy')
      } else {
        return ''
      }
    } catch (error) {
      return ''
    }
  }
}
